// ** React Imports
import React, { useState } from "react";

// ** Third Party Imports
import { FormattedMessage } from "react-intl";

import ModalHOC from "../../hoc/Modal/ModalHOC";

const ModalBonus = props => {
    // ** States
    const [amount, setAmount] = useState("");
    const [user, setUser] = useState("");

    const onSubmit = event => {
        event.preventDefault();
        props.acceptButtonMethod({ amount, user_id: user });
    };

    return (
        <div id="modal" className="modal">
            <div className="modal-background" onClick={props.closeModal}></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{props.title}</p>
                    <button className="delete" aria-label="close" onClick={props.closeModal}></button>
                </header>

                <section className="modal-card-body">
                    <div className="is-desktop">
                        <form onSubmit={onSubmit}>
                            <div className="field">
                                <label className="label">
                                    <FormattedMessage id="invoice.amount"/>
                                </label>
                                <div className="control">
                                    <input
                                        className="input"
                                        max={999999.99}
                                        min={-999999.99}
                                        onChange={event => setAmount(event.target.value)}
                                        step={0.01}
                                        type="number"
                                        value={amount}
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">
                                    <FormattedMessage id="customer.notify_to"/>
                                </label>
                                <div className="select is-fullwidth">
                                    <select onChange={event => setUser(event.target.value)} value={user}>
                                        <option value=""/>
                                        {props.extraData.users?.map(user => (
                                            <option key={user.id} value={user.id}>
                                                {user.name} {user.lastname}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <button className="button is-info floatingButton" type="submit">
                                <FormattedMessage id="general.add"/>
                            </button>
                        </form>
                    </div>
                </section>

                <footer className="modal-card-foot">
                    <div className="h-36"></div>
                </footer>
            </div>
        </div>
    );
}

export default ModalHOC(ModalBonus);
