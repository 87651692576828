// ** React Imports
import React, { useEffect } from "react";

// ** Third Party Imports
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

// ** Icon Imports
import { faCheck, faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ** Store Imports
import { deleteRate, deleteEnableRate } from "../../store/reducers/customers/customersActions";
import { getTranslationTypes } from "../../store/reducers/translationType/translationTypeActions";
import * as userTypes from "../../store/reducers/auth/userTypes"

// ** Actions Import
import * as actionTypes  from "../../store/Actions";

// ** Components Imports
import Datatable from "../Datatable/Datatable";
import Tooltip from "../UI/Tooltip";

// ** Hooks
import { useDate } from "../../hooks/useDate";
import { usePrice } from "../../hooks/usePrice";
import { useRound } from "../../hooks/useRound";

const RatesTable = ({ rates, customer_id }) => {
    // ** Hooks
    const intl = useIntl();
    const dispatch = useDispatch();
    const translationTypes = useSelector(state => state.translationType.translationTypes);
    const userType = useSelector(state => state.auth.user ? state.auth.user.type : "invitado");
    const locale = useSelector(state => state.locale.locale);
    const { dateTimeFormat } = useDate();
    const price = usePrice();
    const round = useRound();

    useEffect(() => {
        dispatch(getTranslationTypes());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const translationTypeName = id => {
        if (translationTypes.length > 0) {
            const type = translationTypes.find(type => type.id === id);
            if (type) return type[`name_${locale.name}`];
        }

        return "";
    }

    const columns = [
        {
            name: intl.formatMessage({ id: "customer.assignLangSource" }),
            selector: row => row?.source_language?.iso_code && intl.formatMessage({ id: `language.${row?.source_language?.iso_code}` }),
            sortable: true,
            grow: 2
        },
        {
            name: intl.formatMessage({ id: "customer.assignLangTarget" }),
            selector: row => row?.target_language?.iso_code && intl.formatMessage({ id: `language.${row?.target_language?.iso_code}` }),
            sortable: true,
            grow: 2
        },
        ...(userType === userTypes.IDISC_ADMIN ? [
            {
                name: intl.formatMessage({ id: "customer.r1" }),
                selector: row => (
                    <div className="is-align-items-center is-flex" style={{ pointerEvents: "none" }}>
                        {`${price(row.r1, locale.name, 4)} €`}
                        {!customer_id && (
                            <button
                                className="button is-small is-text ml-2 px-2"
                                onClick={event => onClickInfo(event, row)}
                                style={{ pointerEvents: "auto" }}
                                title={intl.formatMessage({ id: "task.translation_type" })}
                            >
                                <FontAwesomeIcon className="has-text-link" icon={faInfo}/>
                            </button>
                        )}
                    </div>
                ),
                sortable: true,
                right: true
            },
            {
                name: intl.formatMessage({ id: "customer.r2" }),
                selector: row => `${price(row.r2, locale.name)} €`,
                sortable: true,
                right: true
            },
            {
                name: intl.formatMessage({ id: "customer.updated_on_date" }),
                selector: row => row.updated_at_format ?? dateTimeFormat(row.updated_at, locale.name),
                sortable: true
            },
            ...(!customer_id ? [
                {
                    name: intl.formatMessage({ id: "customer.check_as_default_group" }),
                    selector: row => (
                        <span
                            className={`tag ${row.default_group ? 'is-success' : 'is-danger'}`}
                            style={{ pointerEvents: "none" }}
                        >
                            <FontAwesomeIcon icon={row.default_group ? faCheck : faTimes}/>
                        </span>
                    ),
                    center: true
                }
            ] : []),
            {
                name: "",
                maxWidth: "100px",
                selector: row => (
                    <button
                        className="button is-small is-danger"
                        onClick={event =>
                            row.from === "general" ? onClickDeleteEnable(event, row.id) : onClickDelete(event, row.id)
                        }
                        title={intl.formatMessage({ id: "general.delete" })}
                    >
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                )
            }
        ] : [
            {
                name: <Tooltip
                    id="tooltip.ht"
                    element={<span className="has-text-black">
                        {intl.formatMessage({id: "customer.ht"})}
                    </span>}
                    title={translationTypeName(1)}
                />,
                selector: row =>`${price(row.r1, locale.name, 4)} €`,
                sortable: true,
                right: true
            },
            {
                name: <Tooltip
                    id="tooltip.stp"
                    element={<span className="has-text-black">
                        {intl.formatMessage({id: "customer.stp"})}
                    </span>}
                    title={translationTypeName(2)}
                />,
                selector: row => translationTypes.length > 0 && `${price(
                    round(row.r1 * (translationTypes.find(type => type.id === 2)?.percent / 100), 4),
                    locale.name,
                    4
                )} €`,
                sortable: true,
                right: true
            },
            {
                name: <Tooltip
                    id="tooltip.stv"
                    element={<span className="has-text-black">
                        {intl.formatMessage({id: "customer.stv"})}
                    </span>}
                    title={translationTypeName(3)}
                />,
                selector: row => translationTypes.length > 0 && `${price(
                    round(row.r1 * (translationTypes.find(type => type.id === 3)?.percent / 100), 4),
                    locale.name,
                    4
                )} €`,
                sortable: true,
                right: true
            },
            {
                name: <Tooltip
                    id="tooltip.str"
                    element={<span className="has-text-black">
                        {intl.formatMessage({id: "customer.stf"})}
                    </span>}
                    title={translationTypeName(4)}
                />,
                selector: row => translationTypes.length > 0 && `${price(
                    round(row.r1 * (translationTypes.find(type => type.id === 4)?.percent / 100), 4),
                    locale.name,
                    4
                )} €`,
                sortable: true,
                right: true
            },
            {
                name: intl.formatMessage({ id: "customer.hour_rate" }),
                selector: row => `${price(row.r2, locale.name)} €`,
                sortable: true,
                right: true
            }
        ])
    ];

    const onClickInfo = (event, rate) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch({
            type: actionTypes.SHOW_MODAL,
            payload: {
                modalType: "info_rate",
                title: intl.formatMessage({ id: "task.translation_type" }),
                extraData: { rate, translationTypes }
            }
        })
    }

    const onClickUpdate = rate => {
        dispatch({
            type: actionTypes.SHOW_MODAL,
            payload: {
                modalType: "editRate",
                title: intl.formatMessage({ id: "customer.editRate" }),
                extraData: { id_rate: rate.id, rate, customer_id }
            }
        })
    }

    const onClickDelete = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch({
            type: "TOGGLE_MODAL",
            payload: {
                title: intl.formatMessage({ id: "message_title.delete" }),
                message: intl.formatMessage({ id: "message.delete" }),
                acceptButton: intl.formatMessage({ id: "general.delete" }),
                cancelButton: intl.formatMessage({ id: "general.cancel" }),
                acceptButtonClass: "is-danger",
                acceptButtonMethod: () => dispatch(deleteRate(id, customer_id)),
            }
        })
    }

    const onClickDeleteEnable = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch({
            type: "TOGGLE_MODAL",
            payload: {
                title: intl.formatMessage({ id: "message_title.delete" }),
                message: intl.formatMessage({ id: "message.delete" }),
                acceptButton: intl.formatMessage({ id: "general.delete" }),
                cancelButton: intl.formatMessage({ id: "general.cancel" }),
                acceptButtonClass: "is-danger",
                acceptButtonMethod: () => dispatch(deleteEnableRate(id, customer_id)),
            }
        })
    }

    return (
        <Datatable
            columns={columns}
            data={rates}
            onRowClicked={row => row.from !== "general" ? onClickUpdate(row) : null}
            pointerOnHover={userType === userTypes.IDISC_ADMIN && !customer_id}
        />
    );
};

export default RatesTable;
